<template>
	<el-scrollbar class="seller-scrollbar">
		<el-card class="seller-card">
			<el-breadcrumb slot="header" separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ name: 'campaignIndex' }">{{ $t('seller.campaign.title') }}</el-breadcrumb-item>
				<el-breadcrumb-item>{{ pageInfo.name }}</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="page-head">
				<div class="title">{{ pageInfo.name }}<el-tag type="info">{{ pageInfo.label_name }}</el-tag>
				</div>
				<ul class="text">
					<li>
						<span>{{ $t('seller.campaign.shopSignUpTime') + '：' }}{{ pageInfo.s_enroll_start_time | parseTime('{y}.{m}.{d} {h}:{i}') }}-{{ pageInfo.s_enroll_end_time | parseTime('{y}.{m}.{d} {h}:{i}') }}</span>
					</li>
					<li>
						<span>{{ $t('seller.campaign.itemSignUpTime') + '：' }}{{ pageInfo.g_enroll_start_time | parseTime('{y}.{m}.{d} {h}:{i}') }}-{{ pageInfo.g_enroll_end_time | parseTime('{y}.{m}.{d} {h}:{i}') }}</span>
					</li>
					<li><span>{{ $t('seller.campaign.activityTime') + '：' + $t('seller.campaign.warmUpText1') }}{{ onActivityDays(pageInfo.warm_up_start_time,pageInfo.warm_up_end_time) }}{{ $t('seller.campaign.warmUpText2') }}{{ onActivityDays(pageInfo.sales_start_time,pageInfo.sales_end_time) }}{{ $t('seller.campaign.warmUpText3') }}</span>
					</li>
				</ul>
			</div>
			<div class="page-step">
				<el-steps :active="pageStep" align-center>
					<el-step :title="$t('seller.campaign.shopSignUp')">
						<template slot="description">{{ pageInfo.s_enroll_start_time | parseTime() }}</template>
					</el-step>
					<el-step :title="$t('seller.campaign.itemSignUp')">
						<template slot="description">{{ pageInfo.g_enroll_end_time | parseTime() }} {{ $t('seller.campaign.end') }}</template>
					</el-step>
					<el-step :title="$t('seller.campaign.activityWarmUp')">
						<template slot="description">{{ pageInfo.warm_up_start_time | parseTime() }}</template>
					</el-step>
					<el-step :title="$t('seller.campaign.activitySales')">
						<template slot="description">{{ pageInfo.sales_start_time | parseTime() }}</template>
					</el-step>
					<el-step :title="$t('seller.campaign.activityEnd')">
						<template slot="description">{{ pageInfo.sales_end_time | parseTime() }}</template>
					</el-step>
				</el-steps>
			</div>
			<div class="page-main" v-if="pageStep === 1">
				<template v-if="pageInfo.g_enroll_start_time > parseInt(Date.now() / 1000)">
					<h4>{{ $t('seller.campaign.signText1') }}</h4>
				</template>
				<template v-else>
					<h4>{{ $t('seller.campaign.signText2') }}</h4>
					<div style="text-align:center">
						<router-link :to="{ name: 'campaignItemApply', params: { id: pageInfo.activity_id}}"><el-button type="primary">{{ $t('seller.actions.goodsSignUp') }}</el-button></router-link>
					</div>
				</template>
			</div>
			<div class="page-main" v-if="pageStep === 2">
				<h4>{{ $t('seller.campaign.signText3') }}</h4>
				<!-- <div style="text-align:center">
					<a :href="$campaignBasePath+'/campaign/detail/'+ pageInfo.activity_id" target="_blank"><el-button type="primary">{{ $t('seller.actions.activityWarmUp') }}</el-button></a>
				</div> -->
			</div>
			<div class="page-main" v-if="pageStep === 3">
				<h4>{{ $t('seller.campaign.signText4') }}</h4>
				<!-- <div style="text-align:center">
					<a :href="$campaignBasePath+'/campaign/detail/'+ pageInfo.activity_id" target="_blank"><el-button type="primary">{{ $t('seller.actions.activitySales') }}</el-button></a>
				</div> -->
			</div>
			<div class="page-main" v-if="pageStep === 4">
				<h4>{{ $t('seller.campaign.signText5') }}</h4>
				<div class="page-filters">
					<el-form inline>
						<el-form-item :label="$t('seller.tableHead.goods')+':'">
							<el-input v-model="pageQuery.goods_ids"></el-input>
						</el-form-item>
						<el-form-item :label="$t('seller.screen.goodsName') + ':'">
							<el-input v-model="pageQuery.goods_name"></el-input>
						</el-form-item>
						<el-form-item :label="$t('seller.screen.goodsStatus') + ':'">
							<el-select v-model="pageQuery.goods_status">
								<el-option v-for="item in $t('seller.options.goodsStatus')" :key="item.label" :label="item.label" :value="item.value"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item :label="$t('seller.screen.campaignStatus') + ':'">
							<el-select v-model="pageQuery.enroll_status">
								<el-option v-for="item in $t('seller.options.campaignStatus')" :key="item.label" :label="item.label" :value="item.value"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item :label="$t('seller.screen.perfectStatus') + ':'">
							<el-select v-model="pageQuery.per_status">
								<el-option v-for="item in $t('seller.options.perfectStatus')" :key="item.label" :label="item.label" :value="item.value"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="handleSearch">{{ $t('seller.actions.search') }}</el-button>
						</el-form-item>
					</el-form>
				</div>
				<el-table v-loading="listLoading" :data="pageList" class="seller-table">
					<el-table-column :label="$t('seller.tableHead.goods')" min-width="200px">
						<template slot-scope="scope">
							<div class="goods-item">
								<a class="pic" :href="$itemBasePath+'/item/'+scope.row.goods_id" target="_blank">
									<img :src="scope.row.default_image" @error="goodsLoadError" alt="">
								</a>
								<div class="item-main">
									<a class="name" :href="$itemBasePath+'/item/'+scope.row.goods_id" target="_blank">{{ scope.row.goods_name }}</a>
									<div class="text">{{ $t('seller.screen.goodsId') + ':'}}{{ scope.row.goods_id }}</div>
									<div class="text">{{ $t('seller.campaign.marketId') + ':'}}{{ scope.row.activity_goods_id }}</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column :label="$t('seller.screen.goodsStatus')">
						<template slot-scope="scope">{{ scope.row.is_violation === 1 ? 2 : scope.row.if_show | typeFilter($t('seller.filters.goodsType'))}}</template>
					</el-table-column>
					<el-table-column :label="$t('seller.formItem.goodsPrice')" prop="price"></el-table-column>
					<el-table-column :label="$t('seller.tableHead.campaignPrice')" prop="activity_price">
						<template slot-scope="scope">
							<template v-if="parseFloat(scope.row.activity_price) > 0">
								{{ scope.row.activity_price | priceFilter() }}
							</template>
						</template>
					</el-table-column>
					<el-table-column :label="$t('seller.tableHead.stock')" prop="activity_stock">
						<template slot-scope="scope">
							{{ parseInt(scope.row.activity_stock) > 0 ? scope.row.activity_stock : $t('seller.campaign.allStock') }}
						</template>
					</el-table-column>
					<el-table-column :label="$t('seller.campaign.signUpTime')">
						<template slot-scope="scope">
							{{ scope.row.enroll_time | parseTime() }}
						</template>
					</el-table-column>
					<el-table-column :label="$t('seller.tableHead.itemSignUpStatus')">
						<template slot-scope="scope">
							<span :class="{red: scope.row.enroll_status === 0}">{{ scope.row.enroll_status | typeFilter($t('seller.filters.itemSignUpStatus')) }}</span>
						</template>
					</el-table-column>
				</el-table>
				<pagination v-show="pageTotal > 0" :total="pageTotal" :page.sync="pageCurr" :limit.sync="pageSize"
					@pagination="getEntryGoods"></pagination>
			</div>
		</el-card>
	</el-scrollbar>
</template>

<script>
import Pagination from '@/components/seller/pagination'
import { fetchCampaignInfo, fetchEntryGoods } from '@/api/seller/market'
export default {
	components: { Pagination },
	data() {
		return {
			pageInfo: {},
			activeName: 'desc',
			submitLoading: false,
			pageStep: 0,
			pageQuery: {
				activity_id: this.$route.params.id,
				goods_ids: '',
				goods_name: '',
				goods_status: '',
				enroll_status: '',
			},
			listLoading: false,
			pageList: [],
			pageCurr: 1,
			pageSize: 10,
			pageTotal: 0
		}
	},
	created() {
		this.getData()
		this.getEntryGoods()
	},
	methods: {
		goodsLoadError(e) {
			e.target.src = this.$imageErrorGoods;
			e.target.style.objectFit = 'fill';
		},
		async getData() {
			const { data } = await fetchCampaignInfo({ activity_id: this.$route.params.id })
			this.pageInfo = data
			this.onActivityStep()
		},
		onActivityStep() {
			var step = 0
			if (this.pageInfo) {
				var currTime = parseInt(Date.now() / 1000)
				if (this.pageInfo.g_enroll_start_time < currTime && this.pageInfo.g_enroll_end_time > currTime) {
					step = 1
				}
				if (this.pageInfo.warm_up_start_time < currTime) {
					step = 2
				}
				if (this.pageInfo.sales_start_time < currTime) {
					step = 3
				}
				if (this.pageInfo.sales_end_time < currTime) {
					step = 4
				}
			}
			this.pageStep = step
		},
		onActivityDays(startTime, endTime) {
			const diff = endTime - startTime
			return Math.ceil(diff / 86400)
		},
		getEntryGoods() {
			this.listLoading = true
			const params = {
				...this.pageQuery,
				page_index: (this.pageCurr - 1) * this.pageSize,
				page_size: this.pageSize
			}
			fetchEntryGoods(params).then(response => {
				const { data } = response
				this.pageList = data.list
				this.pageTotal = parseInt(data.count)
				this.listLoading = false
			})
		},
		handleSearch() {
			this.dialogCurr = 1
			this.getEntryGoods()
		}
	}
}
</script>

<style lang="scss" scoped>
.seller-card {

	.page-head {
		padding: 18px;
		border-radius: 4px;
		background-color: rgb(249, 249, 249);

		.title {
			color: #111;
			font-size: 24px;

			.el-tag {
				color: #111111;
				margin-left: 8px;
				border-color: transparent;
				background-color: rgba(0, 0, 0, 0.06);
			}
		}

		.text {
			margin-top: 9px;

			li {
				font-size: 12px;
				display: inline-block;
				vertical-align: middle;
				margin-right: 12px;

				span {
					margin-right: 4px;
				}

				.el-tag {
					color: rgb(17, 17, 17);
					border-color: transparent;
					background-color: rgba(0, 0, 0, 0.06);
				}
			}
		}
	}

	.page-step {
		margin-top: 18px;
	}

	.page-main {

		h4 {
			font-size: 28px;
			margin: 80px 0;
			text-align: center;
			font-weight: normal;
		}

		.goods-item {
			display: flex;
			font-size: 12px;
			line-height: 1.5;

			.pic {
				flex-shrink: 0;
				width: 60px;
				height: 60px;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			.item-main {
				flex: 1;
				width: 0;
				margin-left: 10px;

				.name {
					color: $--seller-primary;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					margin-bottom: 4px;
				}

				.text {
					color: #999999;
				}
			}
		}

		.red {
			color: #f40;
		}
	}
}
</style>